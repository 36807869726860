import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import login from 'shared-ui/lib/utils/login';
import { RegisterFooter } from '../../../components/RegisterFooter';
import { routesConfig } from '../../../config/routes.config';
import { businessRegisterForm } from '../../../form/register/business-register.form';
import { useRegisterPartnerMutation } from '../../../generated/graphql';

export const RegisterScene = (): JSX.Element => {
  const { t } = useTranslation('RegisterScene');
  const navigate = useNavigate();
  const [register, { loading }] = useRegisterPartnerMutation({
    onCompleted: (data) => {
      if (data) {
        login(data.registerPartnerUser.token);
        navigate(routesConfig.DASHBOARD);
      }
    },
  });

  const handleSubmit = useHandleSubmit(register);

  return (
    <AuthLayout>
      <AuthLogoTitle
        title={t('register')}
        subTitle={t('RegisterScene:iHaveAccount')}
        to={routesConfig.AUTH.LOGIN}
        linkText={t('RegisterScene:login')}
      />
      <AuthCard>
        <FormGen
          form={businessRegisterForm}
          onSubmit={handleSubmit}
          wrapperClassNames="space-y-4"
          FooterElement={<RegisterFooter loading={loading} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
