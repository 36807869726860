import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { partnerInput } from '../../inputs/partner.input';

const form: FormInput[] = [partnerInput.name, partnerInput.photo];

export const partnerForm = createForm(form);

export type PartnerFormBody = {
  name: string;
  photo: string;
};
