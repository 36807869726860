import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { Details } from 'shared-ui/lib/components/Details';
import { Image } from 'shared-ui/lib/components/Image';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { vehicleColumns } from '../../columns/vehicle.columns';
import { AddIconButton } from '../../components/scenes/vehicle-show-scene/AddIconButton';
import { MaintenanceListItem } from '../../components/scenes/vehicle-show-scene/maintenance-list-item/MaintenanceListItem';
import { ReminderListItem } from '../../components/scenes/vehicle-show-scene/reminder-list-item/ReminderListItem';
import { routesConfig } from '../../config/routes.config';
import { useVehicleShowQuery } from '../../generated/graphql';

export const VehicleShowScene = () => {
  const { t } = useTranslation('VehicleShowScene');
  const [showDetails, setShowDetails] = useState(false);

  const columns = [
    vehicleColumns.name,
    vehicleColumns.registerNumber,
    vehicleColumns.brand,
    vehicleColumns.model,
    vehicleColumns.token,
    vehicleColumns.mileage,
    vehicleColumns.VIN,
    vehicleColumns.runningHours,
    vehicleColumns.modelYear,
    vehicleColumns.commissioning,
    vehicleColumns.warranty,
    vehicleColumns.type,
    vehicleColumns.description,
  ];

  const { id } = useParams<{ id: string }>();
  const { data, loading } = useVehicleShowQuery(setupQueryConfig({ id }));

  if (loading || !data) return <AuthLoadingView />;

  const { vehicle } = data;

  const emptyRemainder = (
    <div className="text-sm font-medium text-gray-color mt-2">
      <p>{t('emptyRemainder')}</p>
      <Link
        to={routesConfig.REMINDER.CREATE.replace(':vehicleId', id)}
        className="text-primary cursor-pointer font-bold underline"
      >
        <p className="mt-2">{t('emptyRemainderAddNew')}</p>
      </Link>
    </div>
  );

  const emptyServiceEvent = (
    <div className="text-sm font-medium text-gray-color mt-2">
      <p>{t('emptyServiceEvent')}</p>
      <Link
        to={routesConfig.SERVICE_EVENTS.CREATE.replace(':vehicleId', id)}
        className="text-primary cursor-pointer font-bold underline"
      >
        <p className="mt-2">{t('emptyServiceEventAddNew')}</p>
      </Link>
    </div>
  );

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 mt-1 sm:mt-0">
      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-3 items-center">
            <h2 className="text-lg col-span-2 leading-6 font-bold text-primary">
              {t('vehicleInformation')}
            </h2>
          </div>
          <div className="max-w-screen-lg mt-6 mx-auto">
            <Image src={vehicle.photo} w={500} className="mx-auto rounded-md" />
          </div>
          <div className="mt-6">
            <Details columns={columns} data={vehicle} showMore={showDetails ? undefined : 4} />
            <button
              className="text-secondary cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? t('hideDetails') : t('showDetails')}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-3 items-center">
            <h2 className="text-lg col-span-2 leading-6 font-bold text-primary">{t('reminder')}</h2>
            <div className="flex justify-end">
              <AddIconButton to={routesConfig.REMINDER.CREATE.replace(':vehicleId', id)} />
            </div>
          </div>

          {data.vehicle.reminders.length === 0
            ? emptyRemainder
            : data.vehicle.reminders.map((reminder) => (
                <ReminderListItem key={reminder.id} reminder={reminder} vehicleId={vehicle.id} />
              ))}
        </div>
      </div>

      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-3 items-center">
            <h2 className="text-lg col-span-2 leading-6 font-bold text-primary">
              {t('serviceEvent')}
            </h2>
            <div className="flex justify-end">
              <AddIconButton
                to={routesConfig.SERVICE_EVENTS.CREATE.replace(':vehicleId', vehicle.id)}
              />
            </div>
          </div>
          {data.vehicle.serviceEvents.length === 0
            ? emptyServiceEvent
            : data.vehicle.serviceEvents.map((serviceEvent) => (
                <MaintenanceListItem
                  key={serviceEvent.id}
                  vehicleId={vehicle.id}
                  serviceEvent={serviceEvent}
                />
              ))}
        </div>
      </div>
    </div>
  );
};
