import moment from 'moment';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { ReminderListItemIcon } from './ReminderListItemIcon';
import { ReminderListItemIconCheck } from './ReminderListItemIconCheck';
import { ReminderListItemIconEdit } from './ReminderListItemIconEdit';

type Reminder = {
  id: string;
  title: string;
  date: string;
  isPartnerLocked: boolean;
};

type Props = {
  reminder: Reminder;
  vehicleId: string;
};

export const ReminderListItem = ({ reminder, vehicleId }: Props) => {
  const isLate = moment().isSameOrAfter(moment(reminder.date));

  return (
    <div className="mt-3">
      <div className="grid grid-cols-3 items-center">
        <div className="inline-flex col-span-2 items-center">
          <ReminderListItemIcon />
          <div className="grid">
            <p
              className={`text-lg  ml-2 truncate ${
                isLate ? 'text-red-color font-bold' : 'text-gray-900'
              }`}
            >
              {reminder.title}
            </p>
            <p className={`text-lg ml-2 ${isLate ? 'text-red-color' : 'text-gray-color'}`}>
              {formatDate(reminder.date)}
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          {reminder.isPartnerLocked ? null : (
            <ReminderListItemIconEdit vehicleId={vehicleId} reminder={reminder} />
          )}
          <ReminderListItemIconCheck vehicleId={vehicleId} id={reminder.id} />
        </div>
      </div>
    </div>
  );
};
