import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { CardTitle } from 'shared-ui/lib/components/CardTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { feedbackForm, FeedbackFormBody } from '../../form/feedback.form';
import { useSendFeedbackMutation } from '../../generated/graphql';

export const FeedbackScene = (): JSX.Element => {
  const { t } = useTranslation('FeedbackScene');

  const [sendFeedback, { loading }] = useSendFeedbackMutation();

  const send = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (body: FeedbackFormBody, ...args: any) => {
      const form = args[0];
      await sendFeedback({ variables: body });
      form.resetForm();
      toast.success(t('Common:FeedbackSuccess'));
    },
    [sendFeedback, t]
  );

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <p className="mb-4 text-gray-700 text-sm">{t('text')}</p>
        <FormGen
          form={feedbackForm}
          onSubmit={send}
          FooterElement={
            <div className="mt-3">
              <Button
                loading={loading}
                type="submit"
                className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
              >
                {t('send')}
              </Button>
            </div>
          }
        />
        <div className="clear-both" />
      </Card>
    </div>
  );
};
