export default {
  fi: {
    SideBar: {
      home: 'Ajoneuvohaku',
      settings: 'Asetukset',
      partnerSettings: 'Yritys',
      logOut: 'Kirjaudu ulos',
      team: 'Tiimi',
      feedback: 'Anna palautetta',
    },
  },
};
