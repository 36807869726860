import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { routesConfig } from '../config/routes.config';
import { findVehicleForm, FindVehicleFormBody } from '../form/find-vehicle/find-vehicle.form';
import { useFindVehicleLazyQuery } from '../generated/graphql';

export const HomeScene = () => {
  const { t } = useTranslation('HomeScene');
  const navigate = useNavigate();

  const [findVehicle, { loading }] = useFindVehicleLazyQuery();

  const handleSubmit = useCallback(
    async ({ token }: FindVehicleFormBody) => {
      const { data } = await findVehicle({ variables: { token } });
      if (!data?.searchVehicle) return toast.error('Missing vehicle');
      navigate(routesConfig.VEHICLES.SHOW.replace(':id', data.searchVehicle.id));
    },
    [findVehicle, navigate]
  );

  return (
    <GenericFormScene
      tNS="HomeScene"
      type="create"
      title={t('title')}
      onSubmit={handleSubmit}
      form={findVehicleForm}
      isSubmitLoading={loading}
    />
  );
};
