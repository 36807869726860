import { useTranslation } from 'react-i18next';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { reminderForm } from '../../form/reminder/reminder.form';
import { useCreateReminderMutation } from '../../generated/graphql';

export const ReminderCreateScene = (): JSX.Element => {
  const { t } = useTranslation('ReminderCreateScene');
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const onComplete = useOnCompleteRedirect(routesConfig.VEHICLES.SHOW.replace(':id', vehicleId));

  const [createReminder, { loading }] = useCreateReminderMutation(onComplete);

  const handleSubmit = useHandleSubmit(createReminder, { body: { vehicleId } });

  return (
    <GenericFormScene
      type="create"
      title={t('addReminder')}
      onSubmit={handleSubmit}
      form={reminderForm}
      isSubmitLoading={loading}
      backLink={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
    />
  );
};
