import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { routesConfig } from '../../config/routes.config';
import ReFetchContext from '../../contexes/ReFetchContext';
import UserContext from '../../contexes/UserContext';
import { partnerForm, PartnerFormBody } from '../../form/partner/partner.form';
import { usePartnerQuery, useUpdatePartnerMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const PartnerEditScene = (): JSX.Element => {
  const { t } = useTranslation('PartnerEditScene');
  const { uploadFile, isImageLoading } = useUploadFile({ type: 'partner-image' });
  const [user] = useContext(UserContext);
  const refresh = useContext(ReFetchContext);
  const [updatePartner, { loading }] = useUpdatePartnerMutation({
    onCompleted: () => {
      toast.success(t('General:updated'));
    },
  });

  const partnerQuery = usePartnerQuery(setupQueryConfig());

  const handleSubmit = async ({ photo, ...body }: PartnerFormBody) => {
    const { data, errors } = await updatePartner({ variables: { body } });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    if (!user.partner) throw new Error('[PartnerEditScene] not partner user');
    await uploadFile(photo, user.partner.id);
    refresh();
  };

  return (
    <GenericFormScene
      type="edit"
      title={t('editInformation')}
      query={partnerQuery}
      queryDataPath="partner"
      onSubmit={handleSubmit}
      form={partnerForm}
      isSubmitLoading={loading || isImageLoading}
      backLink={routesConfig.DASHBOARD}
    />
  );
};
