import { useTranslation } from 'react-i18next';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { reminderForm } from '../../form/reminder/reminder.form';
import { useReminderQuery, useUpdateReminderMutation } from '../../generated/graphql';

export const ReminderEditScene = (): JSX.Element => {
  const { t } = useTranslation('ReminderEditScene');
  const { vehicleId, id } = useParams<{ vehicleId: string; id: string }>();

  const onUpdateComplete = useOnCompleteRedirect(
    routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)
  );

  const [updateReminder, { loading }] = useUpdateReminderMutation(onUpdateComplete);

  const reminderQuery = useReminderQuery(setupQueryConfig({ id }));

  const handleSubmit = useHandleSubmit(updateReminder, { id });

  return (
    <GenericFormScene
      type="edit"
      title={t('editReminder')}
      query={reminderQuery}
      queryDataPath="reminder"
      onSubmit={handleSubmit}
      form={reminderForm}
      isSubmitLoading={loading}
      backLink={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
    />
  );
};
