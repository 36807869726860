export default {
  fi: {
    HomeScene: {
      emptyVehiclesTitle: 'Sinulla ei ole yhtään ajoneuvoa lisättynä.',
      emptyVehiclessAddNew: 'Lisää uusi ajoneuvo tästä.',
      emptyRemindersTitle: 'Viimeisimmät muistutukset',
      emptyRemindersInformation: 'Sinulla ei ole tekemättömiä muistutuksia.',
      title: 'Ajoneuvohaku',
      save: 'Hae',
    },
  },
};
