import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { reminderInputs } from '../../inputs/reminder.inputs';

const form: FormInput[] = [reminderInputs.title, reminderInputs.date];

export const reminderForm = createForm(form);

export type ReminderFormBody = {
  title: string;
  date: string;
};
