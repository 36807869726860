import {
  AnnotationIcon,
  BriefcaseIcon,
  CogIcon,
  HomeIcon,
  LogoutIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { List } from 'immutable';
import { useContext } from 'react';
import UserContext from '../contexes/UserContext';
import { routesConfig } from './routes.config';

const sidebarConfig = [
  { name: 'home', href: routesConfig.DASHBOARD, icon: HomeIcon, current: false, exact: true },
  { name: 'settings', href: routesConfig.SETTINGS.USER_EDIT, icon: CogIcon, current: false },
  { name: 'feedback', href: routesConfig.FEEDBACK, icon: AnnotationIcon, current: false },
];

export const useSideBarConfig = () => {
  const [user] = useContext(UserContext);

  let list = List(sidebarConfig);

  if (user.isPartnerAccountOwner) {
    list = list.push({
      name: 'partnerSettings',
      href: routesConfig.SETTINGS.PARTNER_EDIT,
      icon: BriefcaseIcon,
      current: false,
    });

    list = list.push({
      name: 'team',
      href: routesConfig.SETTINGS.TEAM.INDEX,
      icon: UsersIcon,
      current: false,
    });
  }

  list = list.push({
    name: 'logOut',
    href: routesConfig.AUTH.LOGOUT,
    icon: LogoutIcon,
    current: false,
  });

  return list.toArray();
};
