export default {
  fi: {
    ServiceEventShowScene: {
      partner: 'Huoltaja',
      serviceEventType: 'Huoltotyyppi',
      serviceEventServiceDate: 'Huoltopäivä',
      serviceEventMileage: 'Kilometrit',
      serviceEventRunningHours: 'Käyttötunnit',
      serviceEventDescription: 'Kuvaus',
      backLink: 'Takaisin',
    },
  },
};
