import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [
  userInputs.email,
  userInputs.partnerName,
  userInputs.name,
  userInputs.password,
  userInputs.terms,
  userInputs.isAccepted,
];

export const businessRegisterForm = createForm(form);

export type BusinessRegisterFormBody = {
  email: string;
  name: string;
  partnerName: string;
  password: string;
  isAccepted: boolean;
};
