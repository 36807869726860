import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AcceptInvitationDto = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AdminBusinessType = {
  __typename?: 'AdminBusinessType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type AdminOptionsDto = {
  archiveUser: Scalars['String']['input'];
  stripePriceId: Scalars['String']['input'];
};

export type AdminOptionsType = {
  __typename?: 'AdminOptionsType';
  archiveUser: Scalars['String']['output'];
  id: Scalars['String']['output'];
  stripePriceId: Scalars['String']['output'];
};

export type AdminPartnerType = {
  __typename?: 'AdminPartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdminUpdateUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminUpdateVehicleDto = {
  isArchived: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  business?: Maybe<AdminBusinessType>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<AdminPartnerType>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AdminVehicleList = {
  __typename?: 'AdminVehicleList';
  list: Array<AdminVehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type AdminVehicleSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminVehicleType = {
  __typename?: 'AdminVehicleType';
  VIN?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  business?: Maybe<AdminBusinessType>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<AdminUserType>;
};

export type AdminVehicleWhere = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: UserType;
};

export type AuthStatusResponse = {
  __typename?: 'AuthStatusResponse';
  status: Scalars['String']['output'];
};

export type BusinessType = {
  __typename?: 'BusinessType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChangeUserInformationDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompleteReminderDto = {
  mileage?: InputMaybe<Scalars['Int']['input']>;
  reminderId: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceEvents: Array<UpdateServiceEventDto>;
  vehicleId: Scalars['String']['input'];
};

export type CompleteReminderResponse = {
  __typename?: 'CompleteReminderResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type ConfirmDto = {
  confirmToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['String']['input'];
};

export type CreateReminderDto = {
  date: Scalars['DateTime']['input'];
  faultId?: InputMaybe<Scalars['String']['input']>;
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FaultList = {
  __typename?: 'FaultList';
  list: Array<FaultType>;
  totalCount: Scalars['Int']['output'];
};

export type FaultSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type FaultType = {
  __typename?: 'FaultType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDone: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  user: UserType;
  vehicle: VehicleType;
};

export type FaultWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbackStatusType = {
  __typename?: 'FeedbackStatusType';
  status: Scalars['String']['output'];
};

export type ForgotPasswordDto = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String']['output'];
};

export type InviteBusinessUserDto = {
  email: Scalars['String']['input'];
  isEmployee: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type InviteUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  aDeleteUser: AdminUserType;
  aUpdateUser: AdminUserType;
  aUpdateVehicle: AdminVehicleType;
  acceptInvitationToken: Scalars['Boolean']['output'];
  changeUserInformation: UserType;
  completeReminder: CompleteReminderResponse;
  confirm: AuthStatusResponse;
  createFault: FaultType;
  createReminder: ReminderType;
  createServiceEvent: ServiceEventType;
  createTransferToken: Scalars['String']['output'];
  createVehicle: VehicleType;
  createVoucher: VoucherType;
  deleteFault: FaultType;
  deleteReminder: ReminderType;
  deleteServiceEvent: ServiceEventType;
  deleteUser: UserType;
  deleteVehicle: VehicleType;
  forgotPasswordConsumer: ForgotPasswordResponse;
  forgotPasswordEmployee: ForgotPasswordResponse;
  forgotPasswordPartner: ForgotPasswordResponse;
  inviteBusinessUser: UserType;
  invitePartnerUser: UserType;
  loginClient: AuthResponse;
  loginEmployee: AuthResponse;
  loginPartner: AuthResponse;
  markAsRead: Scalars['Boolean']['output'];
  registerBusinessUser: AuthResponse;
  registerConsumerUser: AuthResponse;
  registerPartnerUser: AuthResponse;
  requestAccountDelete: Scalars['Boolean']['output'];
  resendConfirmation: AuthStatusResponse;
  resetPassword: ResetPasswordResponse;
  sendFeedback: FeedbackStatusType;
  transferVehicle: TransferVehicleType;
  transferVehicleToBusiness: AdminVehicleType;
  transferVehicleToConsumer: AdminVehicleType;
  updateAdminOptions: AdminOptionsType;
  updateBusiness: BusinessType;
  updateFault: FaultType;
  updateFaultAsDone: FaultType;
  updatePartner: PartnerType;
  updateReminder: ReminderType;
  updateServiceEvent: ServiceEventType;
  updateVehicle: VehicleType;
  updateVoucher: VoucherType;
  useVoucher: VoucherLogType;
  verifyAcceptInvitationToken: Scalars['Boolean']['output'];
};


export type MutationADeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationAUpdateUserArgs = {
  body: AdminUpdateUserDto;
  id: Scalars['String']['input'];
};


export type MutationAUpdateVehicleArgs = {
  body: AdminUpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationAcceptInvitationTokenArgs = {
  body: AcceptInvitationDto;
};


export type MutationChangeUserInformationArgs = {
  body: ChangeUserInformationDto;
};


export type MutationCompleteReminderArgs = {
  body: CompleteReminderDto;
};


export type MutationConfirmArgs = {
  body: ConfirmDto;
};


export type MutationCreateFaultArgs = {
  body: CreateFaultDto;
};


export type MutationCreateReminderArgs = {
  body: CreateReminderDto;
};


export type MutationCreateServiceEventArgs = {
  body: CreateServiceEventDto;
};


export type MutationCreateTransferTokenArgs = {
  vehicleId: Scalars['String']['input'];
};


export type MutationCreateVehicleArgs = {
  body: CreateVehicleDto;
};


export type MutationCreateVoucherArgs = {
  body: VoucherDto;
};


export type MutationDeleteFaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReminderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordConsumerArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordEmployeeArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordPartnerArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteBusinessUserArgs = {
  body: InviteBusinessUserDto;
};


export type MutationInvitePartnerUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginClientArgs = {
  body: LoginDto;
};


export type MutationLoginEmployeeArgs = {
  body: LoginDto;
};


export type MutationLoginPartnerArgs = {
  body: LoginDto;
};


export type MutationMarkAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationRegisterBusinessUserArgs = {
  body: RegisterBusinessUserDto;
};


export type MutationRegisterConsumerUserArgs = {
  body: RegisterConsumerUserDto;
};


export type MutationRegisterPartnerUserArgs = {
  body: RegisterPartnerUserDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationSendFeedbackArgs = {
  message: Scalars['String']['input'];
};


export type MutationTransferVehicleArgs = {
  token: Scalars['String']['input'];
};


export type MutationTransferVehicleToBusinessArgs = {
  businessId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationTransferVehicleToConsumerArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateAdminOptionsArgs = {
  body: AdminOptionsDto;
};


export type MutationUpdateBusinessArgs = {
  body: UpdateBusinessDto;
};


export type MutationUpdateFaultArgs = {
  body: UpdateFaultDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateFaultAsDoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePartnerArgs = {
  body: UpdatePartnerDto;
};


export type MutationUpdateReminderArgs = {
  body: UpdateReminderDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateServiceEventArgs = {
  body: UpdateServiceEventDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVehicleArgs = {
  body: UpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVoucherArgs = {
  body: VoucherDto;
  id: Scalars['String']['input'];
};


export type MutationUseVoucherArgs = {
  vehicleId: Scalars['String']['input'];
  voucher: Scalars['String']['input'];
};


export type MutationVerifyAcceptInvitationTokenArgs = {
  body: VerifyAcceptInvitationDto;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  aBusiness: AdminBusinessType;
  aUser: AdminUserType;
  aUsers: UserList;
  aVehicle: AdminVehicleType;
  aVehicles: AdminVehicleList;
  adminOptions: AdminOptionsType;
  business: BusinessType;
  createCheckoutSession: StripeCheckoutType;
  exportCSV: Scalars['String']['output'];
  fault: FaultType;
  faults: FaultList;
  partner: PartnerType;
  reminder: ReminderType;
  reminders: ReminderList;
  requestAdminToken: AuthStatusResponse;
  searchVehicle: VehicleType;
  serviceEvent: ServiceEventType;
  users: UserList;
  validateAdminToken: AuthStatusResponse;
  validateResetToken: ValidateResetTokenResponse;
  validateTransferToken: ValidateTransferTokenType;
  vehicle: VehicleType;
  vehicles: VehicleList;
  voucher: VoucherType;
  vouchers: VoucherList;
  whoAmI: UserType;
};


export type QueryABusinessArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryAVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryAVehiclesArgs = {
  orderBy?: InputMaybe<Array<AdminVehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminVehicleWhere>;
};


export type QueryCreateCheckoutSessionArgs = {
  vehicleId: Scalars['String']['input'];
};


export type QueryFaultArgs = {
  id: Scalars['String']['input'];
};


export type QueryFaultsArgs = {
  orderBy?: InputMaybe<Array<FaultSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaultWhere>;
};


export type QueryReminderArgs = {
  id: Scalars['String']['input'];
};


export type QueryRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type QuerySearchVehicleArgs = {
  token: Scalars['String']['input'];
};


export type QueryServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};


export type QueryValidateTransferTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  orderBy?: InputMaybe<Array<VehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
};


export type QueryVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  orderBy?: InputMaybe<Array<VoucherSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhere>;
};

export type RegisterBusinessUserDto = {
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterConsumerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterPartnerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  partnerName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ReminderList = {
  __typename?: 'ReminderList';
  list: Array<ReminderType>;
  totalCount: Scalars['Int']['output'];
};

export type ReminderSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderType = {
  __typename?: 'ReminderType';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isNotificationable?: Maybe<Scalars['Boolean']['output']>;
  isPartnerLocked: Scalars['Boolean']['output'];
  notificationDaysBefore?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ReminderWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ResetPasswordDto = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String']['output'];
};

export type ServiceEventSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceEventType = {
  __typename?: 'ServiceEventType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPartnerLocked: Scalars['Boolean']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  partnerId: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ServiceEventWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StripeCheckoutType = {
  __typename?: 'StripeCheckoutType';
  url: Scalars['String']['output'];
};

export type TransferVehicleType = {
  __typename?: 'TransferVehicleType';
  vehicleId: Scalars['String']['output'];
};

export type UpdateBusinessDto = {
  name: Scalars['String']['input'];
};

export type UpdateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerDto = {
  name: Scalars['String']['input'];
};

export type UpdateReminderDto = {
  date: Scalars['DateTime']['input'];
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type UpdateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<AdminUserType>;
  totalCount: Scalars['Int']['output'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UserType = {
  __typename?: 'UserType';
  business?: Maybe<BusinessType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWhere = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ValidateResetTokenResponse = {
  __typename?: 'ValidateResetTokenResponse';
  status: Scalars['Boolean']['output'];
};

export type ValidateTransferTokenType = {
  __typename?: 'ValidateTransferTokenType';
  isVehicleOwner: Scalars['Boolean']['output'];
};

export type VehicleList = {
  __typename?: 'VehicleList';
  list: Array<VehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type VehicleSort = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  VIN: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  commissioning?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasActiveLicense: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  licenseExpireDate: Scalars['DateTime']['output'];
  licenseStatus: Scalars['String']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  reminders: Array<ReminderType>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceEvents: Array<ServiceEventType>;
  token: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  warranty?: Maybe<Scalars['DateTime']['output']>;
};


export type VehicleTypeRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type VehicleTypeServiceEventsArgs = {
  orderBy?: InputMaybe<Array<ServiceEventSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceEventWhere>;
};

export type VehicleWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VerifyAcceptInvitationDto = {
  inviteToken: Scalars['String']['input'];
};

export type VoucherDto = {
  endDate: Scalars['DateTime']['input'];
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useLimit: Scalars['Int']['input'];
};

export type VoucherList = {
  __typename?: 'VoucherList';
  list: Array<VoucherType>;
  totalCount: Scalars['Int']['output'];
};

export type VoucherLogType = {
  __typename?: 'VoucherLogType';
  id: Scalars['String']['output'];
};

export type VoucherSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherType = {
  __typename?: 'VoucherType';
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useLimit: Scalars['Int']['output'];
  uses: Scalars['Int']['output'];
};

export type VoucherWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FindVehicleQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type FindVehicleQuery = { __typename?: 'Query', searchVehicle: { __typename?: 'VehicleType', id: string } };

export type ResendConfirmMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendConfirmMutation = { __typename?: 'Mutation', resendConfirmation: { __typename?: 'AuthStatusResponse', status: string } };

export type SendFeedbackMutationVariables = Exact<{
  message: Scalars['String']['input'];
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', sendFeedback: { __typename?: 'FeedbackStatusType', status: string } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', id: string, email: string, name: string, isConfirmed: boolean, isPartnerAccountOwner: boolean, partner?: { __typename?: 'PartnerType', id: string, name: string, photo?: string | null } | null } };

export type VerifyAcceptInvitationTokenMutationVariables = Exact<{
  body: VerifyAcceptInvitationDto;
}>;


export type VerifyAcceptInvitationTokenMutation = { __typename?: 'Mutation', verifyAcceptInvitationToken: boolean };

export type AcceptInvitationMutationVariables = Exact<{
  body: AcceptInvitationDto;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitationToken: boolean };

export type ConfirmMutationVariables = Exact<{
  body: ConfirmDto;
}>;


export type ConfirmMutation = { __typename?: 'Mutation', confirm: { __typename?: 'AuthStatusResponse', status: string } };

export type ForgotPasswordMutationVariables = Exact<{
  body: ForgotPasswordDto;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPasswordPartner: { __typename?: 'ForgotPasswordResponse', status: string } };

export type LoginMutationVariables = Exact<{
  body: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginPartner: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'UserType', id: string, name: string } } };

export type RegisterPartnerMutationVariables = Exact<{
  body: RegisterPartnerUserDto;
}>;


export type RegisterPartnerMutation = { __typename?: 'Mutation', registerPartnerUser: { __typename?: 'AuthResponse', token: string } };

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordDto;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', status: string } };

export type ValidateResetTokenQueryVariables = Exact<{
  body: ValidateResetTokenDto;
}>;


export type ValidateResetTokenQuery = { __typename?: 'Query', validateResetToken: { __typename?: 'ValidateResetTokenResponse', status: boolean } };

export type CreateReminderMutationVariables = Exact<{
  body: CreateReminderDto;
}>;


export type CreateReminderMutation = { __typename?: 'Mutation', createReminder: { __typename?: 'ReminderType', id: string } };

export type UpdateReminderMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateReminderDto;
}>;


export type UpdateReminderMutation = { __typename?: 'Mutation', updateReminder: { __typename?: 'ReminderType', id: string } };

export type ReminderQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReminderQuery = { __typename?: 'Query', reminder: { __typename?: 'ReminderType', title: string, date: any } };

export type RemindersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ReminderSort> | ReminderSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
}>;


export type RemindersQuery = { __typename?: 'Query', reminders: { __typename?: 'ReminderList', totalCount: number, list: Array<{ __typename?: 'ReminderType', id: string, title: string, date: any, vehicleId: string, vehicle: { __typename?: 'VehicleType', id: string, name?: string | null, registerNumber?: string | null, VIN: string, brand?: string | null, model?: string | null } }> } };

export type CompleteReminderMutationVariables = Exact<{
  body: CompleteReminderDto;
}>;


export type CompleteReminderMutation = { __typename?: 'Mutation', completeReminder: { __typename?: 'CompleteReminderResponse', count: number, ids: Array<string> } };

export type CreateServiceEventMutationVariables = Exact<{
  body: CreateServiceEventDto;
}>;


export type CreateServiceEventMutation = { __typename?: 'Mutation', createServiceEvent: { __typename?: 'ServiceEventType', id: string } };

export type UpdateServiceEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateServiceEventDto;
}>;


export type UpdateServiceEventMutation = { __typename?: 'Mutation', updateServiceEvent: { __typename?: 'ServiceEventType', id: string } };

export type EditServiceEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EditServiceEventQuery = { __typename?: 'Query', serviceEvent: { __typename?: 'ServiceEventType', type: string, name: string, photo?: string | null, serviceDate: any, mileage?: number | null, runningHours?: number | null, description?: string | null } };

export type ServiceEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ServiceEventQuery = { __typename?: 'Query', serviceEvent: { __typename?: 'ServiceEventType', type: string, name: string, serviceDate: any, mileage?: number | null, photo?: string | null, runningHours?: number | null, description?: string | null, partner?: { __typename?: 'PartnerType', name: string } | null } };

export type UpdatePartnerMutationVariables = Exact<{
  body: UpdatePartnerDto;
}>;


export type UpdatePartnerMutation = { __typename?: 'Mutation', updatePartner: { __typename?: 'PartnerType', id: string } };

export type PartnerQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerQuery = { __typename?: 'Query', partner: { __typename?: 'PartnerType', name: string, photo?: string | null } };

export type ChangeUserInformationMutationVariables = Exact<{
  body: ChangeUserInformationDto;
}>;


export type ChangeUserInformationMutation = { __typename?: 'Mutation', changeUserInformation: { __typename?: 'UserType', id: string } };

export type UserEditWhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEditWhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', email: string, name: string } };

export type TeamCreateSceneMutationVariables = Exact<{
  body: InviteUserDto;
}>;


export type TeamCreateSceneMutation = { __typename?: 'Mutation', invitePartnerUser: { __typename?: 'UserType', id: string } };

export type TeamIndexSceneQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamIndexSceneQuery = { __typename?: 'Query', users: { __typename?: 'UserList', totalCount: number, list: Array<{ __typename?: 'AdminUserType', id: string, name: string, email: string, isPartnerAccountOwner: boolean }> } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'UserType', id: string } };

export type VehicleShowQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VehicleShowQuery = { __typename?: 'Query', vehicle: { __typename?: 'VehicleType', id: string, token: string, name?: string | null, type?: string | null, registerNumber?: string | null, VIN: string, mileage?: number | null, modelYear?: number | null, commissioning?: any | null, warranty?: any | null, runningHours?: number | null, model?: string | null, brand?: string | null, photo?: string | null, description?: string | null, reminders: Array<{ __typename?: 'ReminderType', id: string, title: string, date: any, isPartnerLocked: boolean }>, serviceEvents: Array<{ __typename?: 'ServiceEventType', id: string, name: string, serviceDate: any, description?: string | null, isPartnerLocked: boolean, partner?: { __typename?: 'PartnerType', photo?: string | null } | null }> } };


export const FindVehicleDocument = gql`
    query FindVehicle($token: String!) {
  searchVehicle(token: $token) {
    id
  }
}
    `;

/**
 * __useFindVehicleQuery__
 *
 * To run a query within a React component, call `useFindVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVehicleQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFindVehicleQuery(baseOptions: Apollo.QueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables> & ({ variables: FindVehicleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
      }
export function useFindVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export function useFindVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export type FindVehicleQueryHookResult = ReturnType<typeof useFindVehicleQuery>;
export type FindVehicleLazyQueryHookResult = ReturnType<typeof useFindVehicleLazyQuery>;
export type FindVehicleSuspenseQueryHookResult = ReturnType<typeof useFindVehicleSuspenseQuery>;
export type FindVehicleQueryResult = Apollo.QueryResult<FindVehicleQuery, FindVehicleQueryVariables>;
export const ResendConfirmDocument = gql`
    mutation ResendConfirm {
  resendConfirmation {
    status
  }
}
    `;
export type ResendConfirmMutationFn = Apollo.MutationFunction<ResendConfirmMutation, ResendConfirmMutationVariables>;

/**
 * __useResendConfirmMutation__
 *
 * To run a mutation, you first call `useResendConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmMutation, { data, loading, error }] = useResendConfirmMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmMutation, ResendConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmMutation, ResendConfirmMutationVariables>(ResendConfirmDocument, options);
      }
export type ResendConfirmMutationHookResult = ReturnType<typeof useResendConfirmMutation>;
export type ResendConfirmMutationResult = Apollo.MutationResult<ResendConfirmMutation>;
export type ResendConfirmMutationOptions = Apollo.BaseMutationOptions<ResendConfirmMutation, ResendConfirmMutationVariables>;
export const SendFeedbackDocument = gql`
    mutation SendFeedback($message: String!) {
  sendFeedback(message: $message) {
    status
  }
}
    `;
export type SendFeedbackMutationFn = Apollo.MutationFunction<SendFeedbackMutation, SendFeedbackMutationVariables>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(SendFeedbackDocument, options);
      }
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<SendFeedbackMutation, SendFeedbackMutationVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    email
    name
    isConfirmed
    isPartnerAccountOwner
    partner {
      id
      name
      photo
    }
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export function useWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmISuspenseQueryHookResult = ReturnType<typeof useWhoAmISuspenseQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const VerifyAcceptInvitationTokenDocument = gql`
    mutation VerifyAcceptInvitationToken($body: VerifyAcceptInvitationDto!) {
  verifyAcceptInvitationToken(body: $body)
}
    `;
export type VerifyAcceptInvitationTokenMutationFn = Apollo.MutationFunction<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>;

/**
 * __useVerifyAcceptInvitationTokenMutation__
 *
 * To run a mutation, you first call `useVerifyAcceptInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAcceptInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAcceptInvitationTokenMutation, { data, loading, error }] = useVerifyAcceptInvitationTokenMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVerifyAcceptInvitationTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>(VerifyAcceptInvitationTokenDocument, options);
      }
export type VerifyAcceptInvitationTokenMutationHookResult = ReturnType<typeof useVerifyAcceptInvitationTokenMutation>;
export type VerifyAcceptInvitationTokenMutationResult = Apollo.MutationResult<VerifyAcceptInvitationTokenMutation>;
export type VerifyAcceptInvitationTokenMutationOptions = Apollo.BaseMutationOptions<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($body: AcceptInvitationDto!) {
  acceptInvitationToken(body: $body)
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const ConfirmDocument = gql`
    mutation Confirm($body: ConfirmDto!) {
  confirm(body: $body) {
    status
  }
}
    `;
export type ConfirmMutationFn = Apollo.MutationFunction<ConfirmMutation, ConfirmMutationVariables>;

/**
 * __useConfirmMutation__
 *
 * To run a mutation, you first call `useConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMutation, { data, loading, error }] = useConfirmMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMutation, ConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMutation, ConfirmMutationVariables>(ConfirmDocument, options);
      }
export type ConfirmMutationHookResult = ReturnType<typeof useConfirmMutation>;
export type ConfirmMutationResult = Apollo.MutationResult<ConfirmMutation>;
export type ConfirmMutationOptions = Apollo.BaseMutationOptions<ConfirmMutation, ConfirmMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($body: ForgotPasswordDto!) {
  forgotPasswordPartner(body: $body) {
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($body: LoginDto!) {
  loginPartner(body: $body) {
    token
    user {
      id
      name
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterPartnerDocument = gql`
    mutation RegisterPartner($body: RegisterPartnerUserDto!) {
  registerPartnerUser(body: $body) {
    token
  }
}
    `;
export type RegisterPartnerMutationFn = Apollo.MutationFunction<RegisterPartnerMutation, RegisterPartnerMutationVariables>;

/**
 * __useRegisterPartnerMutation__
 *
 * To run a mutation, you first call `useRegisterPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPartnerMutation, { data, loading, error }] = useRegisterPartnerMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRegisterPartnerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPartnerMutation, RegisterPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterPartnerMutation, RegisterPartnerMutationVariables>(RegisterPartnerDocument, options);
      }
export type RegisterPartnerMutationHookResult = ReturnType<typeof useRegisterPartnerMutation>;
export type RegisterPartnerMutationResult = Apollo.MutationResult<RegisterPartnerMutation>;
export type RegisterPartnerMutationOptions = Apollo.BaseMutationOptions<RegisterPartnerMutation, RegisterPartnerMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($body: ResetPasswordDto!) {
  resetPassword(body: $body) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ValidateResetTokenDocument = gql`
    query ValidateResetToken($body: ValidateResetTokenDto!) {
  validateResetToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables> & ({ variables: ValidateResetTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export function useValidateResetTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenSuspenseQueryHookResult = ReturnType<typeof useValidateResetTokenSuspenseQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;
export const CreateReminderDocument = gql`
    mutation CreateReminder($body: CreateReminderDto!) {
  createReminder(body: $body) {
    id
  }
}
    `;
export type CreateReminderMutationFn = Apollo.MutationFunction<CreateReminderMutation, CreateReminderMutationVariables>;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderMutation, CreateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReminderMutation, CreateReminderMutationVariables>(CreateReminderDocument, options);
      }
export type CreateReminderMutationHookResult = ReturnType<typeof useCreateReminderMutation>;
export type CreateReminderMutationResult = Apollo.MutationResult<CreateReminderMutation>;
export type CreateReminderMutationOptions = Apollo.BaseMutationOptions<CreateReminderMutation, CreateReminderMutationVariables>;
export const UpdateReminderDocument = gql`
    mutation UpdateReminder($id: String!, $body: UpdateReminderDto!) {
  updateReminder(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateReminderMutationFn = Apollo.MutationFunction<UpdateReminderMutation, UpdateReminderMutationVariables>;

/**
 * __useUpdateReminderMutation__
 *
 * To run a mutation, you first call `useUpdateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderMutation, { data, loading, error }] = useUpdateReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderMutation, UpdateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderMutation, UpdateReminderMutationVariables>(UpdateReminderDocument, options);
      }
export type UpdateReminderMutationHookResult = ReturnType<typeof useUpdateReminderMutation>;
export type UpdateReminderMutationResult = Apollo.MutationResult<UpdateReminderMutation>;
export type UpdateReminderMutationOptions = Apollo.BaseMutationOptions<UpdateReminderMutation, UpdateReminderMutationVariables>;
export const ReminderDocument = gql`
    query Reminder($id: String!) {
  reminder(id: $id) {
    title
    date
  }
}
    `;

/**
 * __useReminderQuery__
 *
 * To run a query within a React component, call `useReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReminderQuery(baseOptions: Apollo.QueryHookOptions<ReminderQuery, ReminderQueryVariables> & ({ variables: ReminderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
      }
export function useReminderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReminderQuery, ReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
        }
export function useReminderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReminderQuery, ReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
        }
export type ReminderQueryHookResult = ReturnType<typeof useReminderQuery>;
export type ReminderLazyQueryHookResult = ReturnType<typeof useReminderLazyQuery>;
export type ReminderSuspenseQueryHookResult = ReturnType<typeof useReminderSuspenseQuery>;
export type ReminderQueryResult = Apollo.QueryResult<ReminderQuery, ReminderQueryVariables>;
export const RemindersDocument = gql`
    query Reminders($orderBy: [ReminderSort!], $skip: Int, $take: Int, $where: ReminderWhere) {
  reminders(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    totalCount
    list {
      id
      title
      date
      vehicleId
      vehicle {
        id
        name
        registerNumber
        VIN
        brand
        model
      }
    }
  }
}
    `;

/**
 * __useRemindersQuery__
 *
 * To run a query within a React component, call `useRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemindersQuery(baseOptions?: Apollo.QueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
      }
export function useRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
        }
export function useRemindersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
        }
export type RemindersQueryHookResult = ReturnType<typeof useRemindersQuery>;
export type RemindersLazyQueryHookResult = ReturnType<typeof useRemindersLazyQuery>;
export type RemindersSuspenseQueryHookResult = ReturnType<typeof useRemindersSuspenseQuery>;
export type RemindersQueryResult = Apollo.QueryResult<RemindersQuery, RemindersQueryVariables>;
export const CompleteReminderDocument = gql`
    mutation CompleteReminder($body: CompleteReminderDto!) {
  completeReminder(body: $body) {
    count
    ids
  }
}
    `;
export type CompleteReminderMutationFn = Apollo.MutationFunction<CompleteReminderMutation, CompleteReminderMutationVariables>;

/**
 * __useCompleteReminderMutation__
 *
 * To run a mutation, you first call `useCompleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeReminderMutation, { data, loading, error }] = useCompleteReminderMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteReminderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteReminderMutation, CompleteReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteReminderMutation, CompleteReminderMutationVariables>(CompleteReminderDocument, options);
      }
export type CompleteReminderMutationHookResult = ReturnType<typeof useCompleteReminderMutation>;
export type CompleteReminderMutationResult = Apollo.MutationResult<CompleteReminderMutation>;
export type CompleteReminderMutationOptions = Apollo.BaseMutationOptions<CompleteReminderMutation, CompleteReminderMutationVariables>;
export const CreateServiceEventDocument = gql`
    mutation CreateServiceEvent($body: CreateServiceEventDto!) {
  createServiceEvent(body: $body) {
    id
  }
}
    `;
export type CreateServiceEventMutationFn = Apollo.MutationFunction<CreateServiceEventMutation, CreateServiceEventMutationVariables>;

/**
 * __useCreateServiceEventMutation__
 *
 * To run a mutation, you first call `useCreateServiceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceEventMutation, { data, loading, error }] = useCreateServiceEventMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateServiceEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceEventMutation, CreateServiceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceEventMutation, CreateServiceEventMutationVariables>(CreateServiceEventDocument, options);
      }
export type CreateServiceEventMutationHookResult = ReturnType<typeof useCreateServiceEventMutation>;
export type CreateServiceEventMutationResult = Apollo.MutationResult<CreateServiceEventMutation>;
export type CreateServiceEventMutationOptions = Apollo.BaseMutationOptions<CreateServiceEventMutation, CreateServiceEventMutationVariables>;
export const UpdateServiceEventDocument = gql`
    mutation UpdateServiceEvent($id: String!, $body: UpdateServiceEventDto!) {
  updateServiceEvent(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateServiceEventMutationFn = Apollo.MutationFunction<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>;

/**
 * __useUpdateServiceEventMutation__
 *
 * To run a mutation, you first call `useUpdateServiceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceEventMutation, { data, loading, error }] = useUpdateServiceEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateServiceEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>(UpdateServiceEventDocument, options);
      }
export type UpdateServiceEventMutationHookResult = ReturnType<typeof useUpdateServiceEventMutation>;
export type UpdateServiceEventMutationResult = Apollo.MutationResult<UpdateServiceEventMutation>;
export type UpdateServiceEventMutationOptions = Apollo.BaseMutationOptions<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>;
export const EditServiceEventDocument = gql`
    query EditServiceEvent($id: String!) {
  serviceEvent(id: $id) {
    type
    name
    photo
    serviceDate
    mileage
    runningHours
    description
  }
}
    `;

/**
 * __useEditServiceEventQuery__
 *
 * To run a query within a React component, call `useEditServiceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditServiceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditServiceEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditServiceEventQuery(baseOptions: Apollo.QueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables> & ({ variables: EditServiceEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
      }
export function useEditServiceEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
        }
export function useEditServiceEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
        }
export type EditServiceEventQueryHookResult = ReturnType<typeof useEditServiceEventQuery>;
export type EditServiceEventLazyQueryHookResult = ReturnType<typeof useEditServiceEventLazyQuery>;
export type EditServiceEventSuspenseQueryHookResult = ReturnType<typeof useEditServiceEventSuspenseQuery>;
export type EditServiceEventQueryResult = Apollo.QueryResult<EditServiceEventQuery, EditServiceEventQueryVariables>;
export const ServiceEventDocument = gql`
    query ServiceEvent($id: String!) {
  serviceEvent(id: $id) {
    type
    name
    serviceDate
    mileage
    photo
    runningHours
    description
    partner {
      name
    }
  }
}
    `;

/**
 * __useServiceEventQuery__
 *
 * To run a query within a React component, call `useServiceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceEventQuery(baseOptions: Apollo.QueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables> & ({ variables: ServiceEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
      }
export function useServiceEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
        }
export function useServiceEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
        }
export type ServiceEventQueryHookResult = ReturnType<typeof useServiceEventQuery>;
export type ServiceEventLazyQueryHookResult = ReturnType<typeof useServiceEventLazyQuery>;
export type ServiceEventSuspenseQueryHookResult = ReturnType<typeof useServiceEventSuspenseQuery>;
export type ServiceEventQueryResult = Apollo.QueryResult<ServiceEventQuery, ServiceEventQueryVariables>;
export const UpdatePartnerDocument = gql`
    mutation UpdatePartner($body: UpdatePartnerDTO!) {
  updatePartner(body: $body) {
    id
  }
}
    `;
export type UpdatePartnerMutationFn = Apollo.MutationFunction<UpdatePartnerMutation, UpdatePartnerMutationVariables>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument, options);
      }
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = Apollo.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>;
export const PartnerDocument = gql`
    query Partner {
  partner {
    name
    photo
  }
}
    `;

/**
 * __usePartnerQuery__
 *
 * To run a query within a React component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerQuery(baseOptions?: Apollo.QueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
      }
export function usePartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
        }
export function usePartnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
        }
export type PartnerQueryHookResult = ReturnType<typeof usePartnerQuery>;
export type PartnerLazyQueryHookResult = ReturnType<typeof usePartnerLazyQuery>;
export type PartnerSuspenseQueryHookResult = ReturnType<typeof usePartnerSuspenseQuery>;
export type PartnerQueryResult = Apollo.QueryResult<PartnerQuery, PartnerQueryVariables>;
export const ChangeUserInformationDocument = gql`
    mutation ChangeUserInformation($body: ChangeUserInformationDto!) {
  changeUserInformation(body: $body) {
    id
  }
}
    `;
export type ChangeUserInformationMutationFn = Apollo.MutationFunction<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>;

/**
 * __useChangeUserInformationMutation__
 *
 * To run a mutation, you first call `useChangeUserInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserInformationMutation, { data, loading, error }] = useChangeUserInformationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useChangeUserInformationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>(ChangeUserInformationDocument, options);
      }
export type ChangeUserInformationMutationHookResult = ReturnType<typeof useChangeUserInformationMutation>;
export type ChangeUserInformationMutationResult = Apollo.MutationResult<ChangeUserInformationMutation>;
export type ChangeUserInformationMutationOptions = Apollo.BaseMutationOptions<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>;
export const UserEditWhoAmIDocument = gql`
    query UserEditWhoAmI {
  whoAmI {
    email
    name
  }
}
    `;

/**
 * __useUserEditWhoAmIQuery__
 *
 * To run a query within a React component, call `useUserEditWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEditWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
      }
export function useUserEditWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
        }
export function useUserEditWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
        }
export type UserEditWhoAmIQueryHookResult = ReturnType<typeof useUserEditWhoAmIQuery>;
export type UserEditWhoAmILazyQueryHookResult = ReturnType<typeof useUserEditWhoAmILazyQuery>;
export type UserEditWhoAmISuspenseQueryHookResult = ReturnType<typeof useUserEditWhoAmISuspenseQuery>;
export type UserEditWhoAmIQueryResult = Apollo.QueryResult<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>;
export const TeamCreateSceneDocument = gql`
    mutation TeamCreateScene($body: InviteUserDto!) {
  invitePartnerUser(body: $body) {
    id
  }
}
    `;
export type TeamCreateSceneMutationFn = Apollo.MutationFunction<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>;

/**
 * __useTeamCreateSceneMutation__
 *
 * To run a mutation, you first call `useTeamCreateSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateSceneMutation, { data, loading, error }] = useTeamCreateSceneMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useTeamCreateSceneMutation(baseOptions?: Apollo.MutationHookOptions<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>(TeamCreateSceneDocument, options);
      }
export type TeamCreateSceneMutationHookResult = ReturnType<typeof useTeamCreateSceneMutation>;
export type TeamCreateSceneMutationResult = Apollo.MutationResult<TeamCreateSceneMutation>;
export type TeamCreateSceneMutationOptions = Apollo.BaseMutationOptions<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>;
export const TeamIndexSceneDocument = gql`
    query TeamIndexScene {
  users {
    list {
      id
      name
      email
      isPartnerAccountOwner
    }
    totalCount
  }
}
    `;

/**
 * __useTeamIndexSceneQuery__
 *
 * To run a query within a React component, call `useTeamIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamIndexSceneQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
      }
export function useTeamIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
        }
export function useTeamIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
        }
export type TeamIndexSceneQueryHookResult = ReturnType<typeof useTeamIndexSceneQuery>;
export type TeamIndexSceneLazyQueryHookResult = ReturnType<typeof useTeamIndexSceneLazyQuery>;
export type TeamIndexSceneSuspenseQueryHookResult = ReturnType<typeof useTeamIndexSceneSuspenseQuery>;
export type TeamIndexSceneQueryResult = Apollo.QueryResult<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const VehicleShowDocument = gql`
    query VehicleShow($id: String!) {
  vehicle(id: $id) {
    id
    token
    name
    type
    registerNumber
    VIN
    mileage
    modelYear
    commissioning
    warranty
    runningHours
    model
    modelYear
    brand
    photo
    description
    photo
    reminders(orderBy: [{date: "asc"}, {title: "asc"}]) {
      id
      title
      date
      isPartnerLocked
    }
    serviceEvents(orderBy: [{serviceDate: "desc"}]) {
      id
      name
      serviceDate
      description
      isPartnerLocked
      partner {
        photo
      }
    }
  }
}
    `;

/**
 * __useVehicleShowQuery__
 *
 * To run a query within a React component, call `useVehicleShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleShowQuery(baseOptions: Apollo.QueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables> & ({ variables: VehicleShowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
      }
export function useVehicleShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
        }
export function useVehicleShowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
        }
export type VehicleShowQueryHookResult = ReturnType<typeof useVehicleShowQuery>;
export type VehicleShowLazyQueryHookResult = ReturnType<typeof useVehicleShowLazyQuery>;
export type VehicleShowSuspenseQueryHookResult = ReturnType<typeof useVehicleShowSuspenseQuery>;
export type VehicleShowQueryResult = Apollo.QueryResult<VehicleShowQuery, VehicleShowQueryVariables>;