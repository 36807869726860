export const routesConfig = {
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER: '/register',
    FORGOT: '/forgot',
    FORGOT_SUCCESS: '/forgot-success',
    CONFIRM: '/confirm',
    ACCEPT_INVITATION: '/accept-invitation',
    RESET: '/reset',
    RESET_SUCCESS: '/reset-success',
  },
  SETTINGS: {
    ROOT: '/settings',
    USER_EDIT: '/settings/user',
    PARTNER_EDIT: '/settings/partner',
    TEAM: {
      INDEX: '/settings/team/index',
      INVITE: '/settings/team/invite',
    },
  },
  VEHICLES: {
    SHOW: '/vehicles/:id',
  },
  SERVICE_EVENTS: {
    COMPLETE_REMINDER: '/vehicles/:vehicleId/reminders/:id/complete-reminder',
    CREATE: '/vehicles/:vehicleId/service-events/new',
    EDIT: '/vehicles/:vehicleId/service-events/:id/edit',
    SHOW: '/vehicles/:vehicleId/service-events/:id',
  },
  REMINDER: {
    INDEX: '/reminders',
    CREATE: '/vehicles/:vehicleId/reminder/new',
    EDIT: '/vehicles/:vehicleId/reminder/:id/edit',
  },
  DASHBOARD: '/',
  REPORT_REMINDER: '/vehicles/:vehicleId/reminder/:id/report',
  FEEDBACK: '/feedback',
};
