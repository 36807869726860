import _ from 'lodash';
import { sharedTranslations } from 'shared-ui/lib/shared-translations';
import serviceEventCompleteReminderFormTranslation from './form/service-event/service-event-complete-reminder.form.translation';
import AcceptInvitationSceneTranslation from './scenes/auth/accept-invitation/AcceptInvitationSceneTranslation';
import ConfirmSceneTranslation from './scenes/auth/confirm/ConfirmSceneTranslation';
import ForgotSuccessSceneTranslation from './scenes/auth/forgot-success/ForgotSuccessSceneTranslation';
import ForgotSceneTranslation from './scenes/auth/forgot/ForgotSceneTranslation';
import LoginSceneTranslation from './scenes/auth/login/LoginSceneTranslation';
import RegisterSceneTranslation from './scenes/auth/register/register.translation';
import ResetSuccessSceneTranslation from './scenes/auth/reset-success/ResetSuccessSceneTranslation';
import ResetPasswordSceneTranslation from './scenes/auth/reset/ResetPasswordSceneTranslation';
import { FeedbackSceneTranslation } from './scenes/feedback/FeedbackScene.translation';
import HomeSceneTranslation from './scenes/HomeSceneTranslation';
import ReminderCreateSceneTranslation from './scenes/reminder/ReminderCreateSceneTranslation';
import ReminderEditSceneTranslation from './scenes/reminder/ReminderEditSceneTranslation';
import { ReminderIndexSceneTranslation } from './scenes/reminder/ReminderIndexSceneTranslation';
import ServiceEventCompleteReminderSceneTranslation from './scenes/service-event/ServiceEventCompleteReminderSceneTranslation';
import ServiceEventCreateSceneTranslation from './scenes/service-event/ServiceEventCreateSceneTranslation';
import ServiceEventEditSceneTranslation from './scenes/service-event/ServiceEventEditSceneTranslation';
import ServiceEventShowSceneTranslation from './scenes/service-event/ServiceEventShowSceneTranslation';
import PartnerEditSceneTranslation from './scenes/settings/PartnerEditSceneTranslation';
import UserEditSceneTranslation from './scenes/settings/UserEditSceneTranslation';
import { TeamCreateSceneTranslation } from './scenes/team/TeamCreateScene.translation';
import { TeamIndexSceneTranslation } from './scenes/team/TeamIndexScene.translation';
import VehicleShowSceneTranslation from './scenes/vehicle/VehicleShowSceneTranslation';
import { commonTranslation } from './translations/common.translation';
import { FeedbackTranslation } from './translations/feedback.translation';
import sidebarTranslation from './translations/sidebar.translation';

export default _.merge(
  sharedTranslations,
  HomeSceneTranslation,
  VehicleShowSceneTranslation,
  UserEditSceneTranslation,
  ServiceEventCompleteReminderSceneTranslation,
  ServiceEventCreateSceneTranslation,
  ServiceEventEditSceneTranslation,
  ServiceEventShowSceneTranslation,
  ReminderEditSceneTranslation,
  ReminderCreateSceneTranslation,
  ResetSuccessSceneTranslation,
  ResetPasswordSceneTranslation,
  RegisterSceneTranslation,
  LoginSceneTranslation,
  ForgotSuccessSceneTranslation,
  ForgotSceneTranslation,
  ConfirmSceneTranslation,
  serviceEventCompleteReminderFormTranslation,
  PartnerEditSceneTranslation,
  ReminderIndexSceneTranslation,
  TeamIndexSceneTranslation,
  TeamCreateSceneTranslation,
  AcceptInvitationSceneTranslation,
  sidebarTranslation,
  FeedbackSceneTranslation,
  FeedbackTranslation,
  commonTranslation
);
