export default {
  fi: {
    RegisterScene: {
      register: 'Rekisteröidy',
      client: 'Yksityishenkilö',
      businessClient: 'Yritys',
      idType: 'Valitse tunnustyyppi:',
      iHaveAccount: 'Minulla on jo tunnukset,',
      login: 'kirjaudu.',
    },
  },
};
