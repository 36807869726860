import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { routesConfig } from '../../config/routes.config';
import ReFetchContext from '../../contexes/ReFetchContext';
import { userForm } from '../../form/user/user.form';
import { useChangeUserInformationMutation, useUserEditWhoAmIQuery } from '../../generated/graphql';

export const UserEditScene = (): JSX.Element => {
  const { t } = useTranslation('UserEditScene');
  const refresh = useContext(ReFetchContext);

  const [updateUser, { loading }] = useChangeUserInformationMutation({
    onCompleted: () => {
      toast.success(t('General:updated'));
      refresh();
    },
  });

  const query = useUserEditWhoAmIQuery(setupQueryConfig());

  const handleSubmit = useHandleSubmit(updateUser);

  return (
    <GenericFormScene
      type="edit"
      title={t('editInformation')}
      query={query}
      queryDataPath="whoAmI"
      onSubmit={handleSubmit}
      form={userForm}
      isSubmitLoading={loading}
      backLink={routesConfig.DASHBOARD}
    />
  );
};
