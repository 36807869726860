import { Route, Routes } from 'react-router';
import { ScrollToTop } from 'shared-ui/lib/components/ScrollOnTop';
import { RequireAuth } from './components/RequireAuth';
import { RequireGuest } from './components/RequireGuest';
import { routesConfig } from './config/routes.config';
import { DashboardRouter } from './scenes/DashboardRouter';
import { AcceptInvitationScene } from './scenes/auth/accept-invitation/AcceptInvitationScene';
import { ConfirmScene } from './scenes/auth/confirm/ConfirmScene';
import { ForgotSuccessScene } from './scenes/auth/forgot-success/ForgotSuccessScene';
import { ForgotScene } from './scenes/auth/forgot/ForgotScene';
import { LoginScene } from './scenes/auth/login/LoginScene';
import { LogoutScene } from './scenes/auth/logout/Logout';
import { RegisterScene } from './scenes/auth/register/RegisterScene';
import { ResetSuccessScene } from './scenes/auth/reset-success/ResetSuccessScene';
import { ResetPasswordScene } from './scenes/auth/reset/ResetPasswordScene';

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={routesConfig.AUTH.LOGOUT} element={<LogoutScene />} />
        <Route
          path={routesConfig.AUTH.LOGIN}
          element={
            <RequireGuest>
              <LoginScene />
            </RequireGuest>
          }
        />
        <Route
          path={routesConfig.AUTH.REGISTER}
          element={
            <RequireGuest>
              <RegisterScene />
            </RequireGuest>
          }
        />

        <Route
          path={routesConfig.AUTH.FORGOT}
          element={
            <RequireGuest>
              <ForgotScene />
            </RequireGuest>
          }
        />
        <Route
          path={routesConfig.AUTH.FORGOT_SUCCESS}
          element={
            <RequireGuest>
              <ForgotSuccessScene />
            </RequireGuest>
          }
        />
        <Route
          path={routesConfig.AUTH.RESET}
          element={
            <RequireGuest>
              <ResetPasswordScene />
            </RequireGuest>
          }
        />
        <Route
          path={routesConfig.AUTH.RESET_SUCCESS}
          element={
            <RequireGuest>
              <ResetSuccessScene />
            </RequireGuest>
          }
        />
        <Route path={routesConfig.AUTH.CONFIRM} element={<ConfirmScene />} />
        <Route
          path={routesConfig.AUTH.ACCEPT_INVITATION}
          element={
            <RequireGuest>
              <AcceptInvitationScene />
            </RequireGuest>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <DashboardRouter />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default App;
