import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TableColumn } from 'shared-ui/lib/releox-engine/react/generic-table/TableColumn';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../config/routes.config';
import { FindVehicleQuery, VehicleShowQuery } from '../generated/graphql';

const name: TableColumn = {
  field: 'name',
  text: 'Vehicle:name',
  sort: true,
};

const VIN: TableColumn = {
  field: 'VIN',
  text: 'Vehicle:VIN',
  tdClassNames: 'text-gray-color',
  sort: true,
};

const registerNumber: TableColumn = {
  field: 'registerNumber',
  text: 'Vehicle:registerNumber',
  tdClassNames: 'text-gray-color',
};

const mileage: TableColumn = {
  field: 'mileage',
  text: 'Vehicle:mileage',
  tdClassNames: 'text-gray-color',
};

const modelYear: TableColumn = {
  field: 'modelYear',
  text: 'Vehicle:modelYear',
  tdClassNames: 'text-gray-color',
};

const commissioning: TableColumn = {
  field: 'commissioning',
  text: 'Vehicle:commissioning',
  tdClassNames: 'text-gray-color',
  formatter: ({ commissioning: value }: VehicleShowQuery['vehicle']) => {
    if (!value) return '-';
    return formatDate(value);
  },
};

const warranty: TableColumn = {
  field: 'warranty',
  text: 'Vehicle:warranty',
  tdClassNames: 'text-gray-color',
  formatter: ({ warranty: value }: VehicleShowQuery['vehicle']) => {
    if (!value) return '-';
    return formatDate(value);
  },
};

const runningHours: TableColumn = {
  field: 'runningHours',
  text: 'Vehicle:runningHours',
  tdClassNames: 'text-gray-color',
};

const model: TableColumn = {
  field: 'model',
  text: 'Vehicle:model',
  tdClassNames: 'text-gray-color',
};

const brand: TableColumn = {
  field: 'brand',
  text: 'Vehicle:brand',
  tdClassNames: 'text-gray-color',
};

const token: TableColumn = {
  field: 'token',
  text: 'Vehicle:token',
  tdClassNames: 'text-gray-color',
};

const type: TableColumn = {
  field: 'type',
  text: 'Vehicle:type',
  tdClassNames: 'text-gray-color',
  formatter: ({ type: value }: VehicleShowQuery['vehicle']): JSX.Element | string => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    if (!value) return '-';
    return <>{t(`VehicleType:${value}`)}</>;
  },
};

const description: TableColumn = {
  field: 'description',
  text: 'Vehicle:description',
  tdClassNames: 'text-gray-color',
  detailsClassNames: 'grid grid-cols-1 pb-3',
};

const linkToVehicle: TableColumn = {
  field: 'id',
  text: 'General:link',
  formatter: (data: FindVehicleQuery['searchVehicle']) => (
    <Link
      to={routesConfig.VEHICLES.SHOW.replace(':id', data.id)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const vehicleColumns = {
  name,
  VIN,
  registerNumber,
  description,
  type,
  token,
  brand,
  modelYear,
  model,
  runningHours,
  warranty,
  mileage,
  linkToVehicle,
  commissioning,
};
