import { createContext } from 'react';
import { WhoAmIQuery } from '../generated/graphql';

const init: WhoAmIQuery['whoAmI'] = {
  id: '',
  email: '',
  name: '',
  isConfirmed: false,
  isPartnerAccountOwner: false,
};

export default createContext<[WhoAmIQuery['whoAmI'], (user: WhoAmIQuery['whoAmI']) => void]>([
  init,
  () => ({}),
]);
