import { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { ActivateYourAccount } from '../components/ActivateYourAccount';
import { DashboardLayout } from '../components/DashboardLayout';
import { routesConfig } from '../config/routes.config';
import { useSideBarConfig } from '../config/sidebar.config';
import UserContext from '../contexes/UserContext';
import { HomeScene } from './HomeScene';
import { FeedbackScene } from './feedback/FeedbackScene';
import { ReminderCreateScene } from './reminder/ReminderCreateScene';
import { ReminderEditScene } from './reminder/ReminderEditScene';
import { ReminderIndexScene } from './reminder/ReminderIndexScene';
import { ServiceEventCompleteReminderScene } from './service-event/ServiceEventCompleteReminderScene';
import { ServiceEventCreateScene } from './service-event/ServiceEventCreateScene';
import { ServiceEventEditScene } from './service-event/ServiceEventEditScene';
import { ServiceEventShowScene } from './service-event/ServiceEventShowScene';
import { PartnerEditScene } from './settings/PartnerEditScene';
import { UserEditScene } from './settings/UserEditScene';
import { TeamCreateScene } from './team/TeamCreateScene';
import { TeamIndexScene } from './team/TeamIndexScene';
import { VehicleShowScene } from './vehicle/VehicleShowScene';

export const DashboardRouter = () => {
  const [user] = useContext(UserContext);
  const nav = useSideBarConfig();

  if (user && !user.isConfirmed) {
    return <ActivateYourAccount />;
  }

  return (
    <DashboardLayout homeUrl={routesConfig.DASHBOARD} user={user} nav={nav}>
      <Routes>
        <Route path={routesConfig.SETTINGS.TEAM.INDEX} element={<TeamIndexScene />} />
        <Route path={routesConfig.SETTINGS.TEAM.INVITE} element={<TeamCreateScene />} />

        <Route path={routesConfig.VEHICLES.SHOW} element={<VehicleShowScene />} />

        <Route path={routesConfig.REMINDER.CREATE} element={<ReminderCreateScene />} />
        <Route path={routesConfig.REMINDER.EDIT} element={<ReminderEditScene />} />
        <Route path={routesConfig.REMINDER.INDEX} element={<ReminderIndexScene />} />

        <Route
          path={routesConfig.SERVICE_EVENTS.COMPLETE_REMINDER}
          element={<ServiceEventCompleteReminderScene />}
        />
        <Route path={routesConfig.SERVICE_EVENTS.CREATE} element={<ServiceEventCreateScene />} />
        <Route path={routesConfig.SERVICE_EVENTS.EDIT} element={<ServiceEventEditScene />} />
        <Route path={routesConfig.SERVICE_EVENTS.SHOW} element={<ServiceEventShowScene />} />

        <Route path={routesConfig.SETTINGS.USER_EDIT} element={<UserEditScene />} />

        <Route path={routesConfig.DASHBOARD} element={<HomeScene />} />
        <Route path={routesConfig.SETTINGS.USER_EDIT} element={<UserEditScene />} />
        <Route path={routesConfig.FEEDBACK} element={<FeedbackScene />} />
        {user.isPartnerAccountOwner ? (
          <Route path={routesConfig.SETTINGS.PARTNER_EDIT} element={<PartnerEditScene />} />
        ) : undefined}
      </Routes>
    </DashboardLayout>
  );
};
